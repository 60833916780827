import classnames from 'classnames';
import _ from 'lodash';
import React, { forwardRef } from 'react';
// Using module directly due to dependency tree error when bootstrapping
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngleDown,
  faAngleUp,
  faEllipsis,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Badge, { BadgeColor, BadgeProps, BadgeSize } from 'ev-components/Badge';
import Tooltip, { TooltipVariant } from 'ev-components/Tooltip';
import { EVColors } from 'ev-theme/styles';

export enum ButtonTypes {
  Primary = 'contained',
  Secondary = 'outlined',
  Special = 'special',
  Tertiary = 'text',
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  ExtraLarge = 'extraLarge',
}

export enum OpenIndicatorType {
  Angle = 'angle',
  Ellipsis = 'ellipsis',
}

export type ButtonProps = {
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  id: string;
  margin?: string;
  padding?: string;
  variant?: ButtonTypes;
  className?: string;
  disabled?: boolean;
  busy?: boolean;
  busyText?: string;
  type?: undefined | 'submit';
  disableRipple?: boolean;
  label?: string;
  fullWidth?: boolean;
  ariaLabel?: string;
  minWidth?: string;
  maxWidth?: string;
  role?: string;
  startIcon?: IconDefinition;
  endIcon?: IconDefinition;
  icon?: IconDefinition;
  iconColor?: string;
  size?: ButtonSize;
  selected?: boolean;
  open?: boolean;
  cancel?: boolean;
  showOpenIndicator?: boolean;
  openIndicatorType?: OpenIndicatorType;
  href?: MUIButtonProps['href'];
  target?: React.HTMLAttributeAnchorTarget;
  badge?: string;
  badgeProps?: BadgeProps;
  hasToolTip?: boolean;
  tooltipVariant?: TooltipVariant;
  tooltipText?: string;
  form?: string;
  color?: string;
  shouldSetAriaSelected?: boolean;
  textColor?: string;
};

const Button = forwardRef<
  HTMLAnchorElement | HTMLButtonElement | null,
  ButtonProps
>((props, forwardRef) => {
  const {
    busy = false,
    busyText,
    ariaLabel,
    children,
    className,
    disabled = false,
    disableRipple = true,
    endIcon,
    fullWidth = false,
    icon,
    iconColor,
    id,
    label = '',
    margin = '0',
    padding,
    minWidth,
    maxWidth,
    onClick = _.noop,
    size = ButtonSize.Medium,
    startIcon,
    type,
    variant = ButtonTypes.Primary,
    selected = false,
    open = false,
    cancel = false,
    showOpenIndicator = false,
    openIndicatorType = OpenIndicatorType.Angle,
    href,
    target = 'blank',
    badge = null,
    badgeProps,
    hasToolTip = false,
    tooltipVariant = TooltipVariant.Light,
    tooltipText = '',
    form,
    color,
    role,
    shouldSetAriaSelected,
    textColor,
  } = props;

  const { t } = useTranslation();
  const isChildrenEmpty = !children;

  const handleClick: React.MouseEventHandler<HTMLElement> = ev => {
    if (busy || disabled) {
      return;
    }

    onClick(ev);
  };

  const getStartIcon = () => {
    if (busy && !(icon || endIcon)) {
      return (
        <Icon
          data-testid="busy-spinner-start"
          icon={faSpinnerThird}
          fixedWidth
          spin
        />
      );
    } else if (startIcon) {
      return <Icon icon={startIcon} fixedWidth />;
    }
  };

  const getEndIcon = () => {
    if (busy && endIcon) {
      return (
        <Icon
          data-testid="busy-spinner-end"
          icon={faSpinnerThird}
          fixedWidth
          spin
        />
      );
    } else if (endIcon) {
      return <Icon icon={endIcon} fixedWidth />;
    }

    if (icon) {
      return;
    }

    if (_.isUndefined(open)) {
      return;
    }

    if (showOpenIndicator) {
      const openIcon =
        openIndicatorType === OpenIndicatorType.Angle
          ? open
            ? faAngleUp
            : faAngleDown
          : faEllipsis;
      return <Icon icon={openIcon} size="2x" />;
    }
  };

  const getMinWidth = () => {
    if (_.isUndefined(minWidth) && !icon) {
      return '72px';
    }

    return minWidth;
  };

  const getAriaLabel = () => {
    if (busy) {
      return t('Loading...');
    }

    return ariaLabel || label || undefined;
  };

  const getAriaChecked = () => {
    const rolesSupportingAriaChecked = [
      'checkbox',
      'menuitemcheckbox',
      'radio',
      'menuitemradio',
      'switch',
      'option',
      'treeitem',
    ];
    const shouldHaveAriaChecked = rolesSupportingAriaChecked.includes(
      role || '',
    );

    return shouldHaveAriaChecked ? selected : undefined;
  };

  const buttonProps = {
    'aria-label': getAriaLabel(),
    'aria-checked': getAriaChecked(),
    'aria-busy': busy,
    'aria-selected': shouldSetAriaSelected ? selected : undefined,
    className: classnames(className, {
      'ev-selected': selected,
      'ev-open': open,
      'ev-cancel': cancel,
      'ev-fixed-icon': !!icon,
    }),
    'data-testid': id,
    disableRipple: disableRipple,
    disabled: disabled,
    endIcon: getEndIcon(),
    fullWidth: fullWidth,
    id,
    onClick: handleClick,
    size,
    startIcon: getStartIcon(),
    sx: {
      margin,
      '& span': {
        marginRight: isChildrenEmpty ? '0px' : '8px',
      },
      minWidth: getMinWidth(),
      maxWidth,
      padding,
      '&:focus-visible': {
        'box-shadow': `0px 0px 0px 2px ${EVColors.cobalt}, 0px 0px 0px 4px ${EVColors.selectedHover}`,
        '&.Mui-focusVisible::before': {
          border: 'none',
        },
      },
    },
    type,
    variant,
    disableElevation: true,
    disableFocusRipple: true,
    form,
    role,
    style: {
      backgroundColor: color,
      color: textColor,
    },
  };

  const renderChildren = () => {
    if (icon) {
      if (busy) {
        return (
          <i>
            <Icon
              data-testid="busy-spinner-only"
              icon={faSpinnerThird}
              size="2x"
              fixedWidth
              spin
            />
          </i>
        );
      }
      return (
        <i>
          <Icon color={iconColor} icon={icon} size="2x" fixedWidth />
        </i>
      );
    } else {
      return busy && busyText ? busyText : children;
    }
  };

  const renderBadge = () => {
    if (badge !== null) {
      return (
        <Badge
          color={BadgeColor.DecorativePink}
          content={badge}
          disabled={disabled}
          id={id}
          size={BadgeSize.Medium}
          solid
          {...badgeProps}
        />
      );
    }
  };

  if (href) {
    return (
      <MUIButton
        {...buttonProps}
        href={href}
        ref={forwardRef as React.MutableRefObject<HTMLAnchorElement>}
        target={target}
      >
        {renderChildren()}
        {renderBadge()}
      </MUIButton>
    );
  } else if (hasToolTip && !disabled) {
    return (
      <Tooltip id={id} text={tooltipText || label} variant={tooltipVariant}>
        <MUIButton
          {...buttonProps}
          ref={forwardRef as React.MutableRefObject<HTMLButtonElement>}
        >
          {renderChildren()}
          {renderBadge()}
        </MUIButton>
      </Tooltip>
    );
  } else {
    return (
      <MUIButton
        {...buttonProps}
        ref={forwardRef as React.MutableRefObject<HTMLButtonElement>}
      >
        {renderChildren()}
        {renderBadge()}
      </MUIButton>
    );
  }
});

Button.displayName = 'Button';

export default Button;

// Using FontAwesomeIcon directly to avoid cyclic imports.
// Our Icon component also uses the button component.
const Icon = styled(FontAwesomeIcon).attrs({
  className: 'ev-button-icon',
})<{ $color?: string }>`
  ${p => p.$color && `color: ${p.color};`}
`;
