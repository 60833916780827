import {
  AttendeeAttemptingJoinResponse,
  VisitAttendeeInvitedResponse,
  VisitAttendeeRemovedResponse,
  VisitMediaPermissionDeniedResponse,
} from './event-types';
import { usePusherChannelSubscription } from './pusher';

export enum UserEvents {
  AppointmentCreated = 'appointmentCreated',
  AvatarUploaded = 'avatarUploaded',
  VisitAttendeeAttemptingJoin = 'visitAttendeeAttemptingJoin',
  VisitAttendeeInvited = 'visitAttendeeInvited',
  VisitAttendeeAllowed = 'visitAttendeeAllowed',
  VisitAttendeeRemoved = 'visitAttendeeRemoved',
  VisitMediaPermissionDenied = 'visitMediaPermissionDenied',
}

export const useUserNotificationSubscription = <
  Event extends
    | AttendeeAttemptingJoinResponse
    | VisitAttendeeRemovedResponse
    | VisitAttendeeInvitedResponse
    | VisitMediaPermissionDeniedResponse,
>(
  notificationChannel: string,
  event: string,
  handler: (event?: Event) => void,
) => {
  const handleEvent = (event: unknown) => {
    handler(event as Event);
  };

  return usePusherChannelSubscription(notificationChannel, event, handleEvent);
};
