import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import _ from 'lodash';

import {
  ChatEntry,
  PatientEvaultDocument,
  VisitEvaultDocument,
} from 'ev-types';

import { AllAvailableTags, Tags } from 'ev-api/api';
import { sanitizeId } from 'ev-api/utils';
import { EVAULT_HOST } from 'ev-config/config';
import { base64Decode } from 'ev-utils/base64';

import {
  AddEvaultDocumentParams,
  GetChatEvaultDocumentParams,
  GetEvaultDocumentParams,
  UpdateEvaultDocumentParams,
} from './params';
import {
  AddEvaultDocumentResponse,
  ChatEvaultResponse,
  GetBlobParams,
  UpdateEvaultDocumentResponse,
  UploadBlobParams,
  UploadBlobResponse,
} from './responses';
import {
  eVaultFileTransform,
  patientEvaultTransform,
  visitEvaultTransform,
} from './transformers';
import {
  encodeEvaultDocument,
  encodeEvaultToken,
  mergeEvaultDocuments,
} from './utils';

const evaultQuery = (baseUrl = '/r-static/proxy/') => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
      headers.set('x-target-host', EVAULT_HOST);
      return headers;
    },
  });
};

const documentQuery = ({
  vaultId,
  vaultHealthDocId,
  vaultToken,
}: GetEvaultDocumentParams) => {
  const evaultTokenEncoded = encodeEvaultToken(vaultToken);
  const timestamp = new Date();
  return {
    headers: {
      authorization: 'Basic ' + evaultTokenEncoded,
    },
    url: `v1/vaults/${vaultId}/documents/${vaultHealthDocId}`,
    params: {
      ts: timestamp.valueOf(),
    },
    method: 'GET',
    data: {
      ts: timestamp.valueOf(),
    },
  };
};

export const oldEvaultApi = createApi({
  reducerPath: 'evaultApi',
  baseQuery: evaultQuery(),
  tagTypes: AllAvailableTags,
  endpoints: builder => ({
    getPatientEvaultDocument: builder.query<
      PatientEvaultDocument,
      GetEvaultDocumentParams
    >({
      query: documentQuery,
      providesTags: [Tags.EvaultDocument],
      transformResponse: patientEvaultTransform,
    }),
    getVisitEvaultDocument: builder.query<
      VisitEvaultDocument,
      GetEvaultDocumentParams
    >({
      query: documentQuery,
      providesTags: [Tags.EvaultDocument],
      transformResponse: visitEvaultTransform,
    }),
    getChatEvaultDocument: builder.query<
      ChatEntry[],
      GetChatEvaultDocumentParams
    >({
      queryFn: async (
        { vaultId, vaultToken, vaultHealthDocIds, healthRecordId },
        queryApi,
        extraOptions,
        fetch,
      ) => {
        const promises = _.map(vaultHealthDocIds, vaultHealthDocId =>
          fetch(
            documentQuery({
              vaultId,
              vaultToken,
              vaultHealthDocId,
              healthRecordId,
            }),
          ),
        );

        const results = await Promise.all(promises);
        const resultWithError = _.find(results, result => !!result.error);

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (resultWithError && resultWithError.error) {
          return { error: resultWithError.error };
        }

        const chatLog = _.chain(results)
          .map(result => {
            const data = result.data as ChatEvaultResponse;
            const document = JSON.parse(base64Decode(data.document));

            return _.map(document.messages, message => ({
              message: decodeURIComponent(message.message),
              created_at: decodeURIComponent(message.created_at),
              user_id: sanitizeId(message.user_id),
              user_name: decodeURIComponent(message.user_name),
              visit_id: sanitizeId(message.visit_id),
            }));
          })
          .flatten()
          .sortBy('created_at')
          .value();

        return { data: chatLog };
      },
    }),
    updateEvaultDocument: builder.mutation<
      UpdateEvaultDocumentResponse,
      UpdateEvaultDocumentParams
    >({
      query: ({
        vaultId,
        vaultHealthDocId,
        vaultToken,
        currentUserId,
        currentDocument,
        modifiedDocument,
      }) => {
        const updatedDocument = mergeEvaultDocuments(
          currentDocument,
          modifiedDocument,
          currentUserId,
        );

        return {
          headers: {
            authorization: 'Basic ' + encodeEvaultToken(vaultToken),
          },
          url: `v1/vaults/${vaultId}/documents/${vaultHealthDocId}`,
          method: 'PATCH',
          body: {
            document: encodeEvaultDocument(updatedDocument),
          },
        };
      },
      invalidatesTags: [Tags.EvaultDocument, Tags.User],
    }),

    addEvaultDocument: builder.mutation<
      AddEvaultDocumentResponse,
      AddEvaultDocumentParams
    >({
      query: ({ vaultId, vaultToken, document }) => {
        return {
          headers: {
            authorization: 'Basic ' + encodeEvaultToken(vaultToken),
          },
          url: `v1/vaults/${vaultId}/documents`,
          method: 'POST',
          body: {
            document: encodeEvaultDocument(document),
          },
        };
      },
      invalidatesTags: [Tags.EvaultDocument, Tags.User],
    }),
    uploadBlob: builder.mutation<UploadBlobResponse, UploadBlobParams>({
      query: ({ vaultToken, vaultId, blob }) => {
        const formData = new FormData();
        formData.append('file', blob);
        return {
          headers: {
            authorization: 'Basic ' + encodeEvaultToken(vaultToken),
          },
          url: `v1/vaults/${vaultId}/blobs`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getBlob: builder.query<string, GetBlobParams>({
      query: ({ vaultToken, vaultId, blobId }) => {
        return {
          headers: {
            authorization: 'Basic ' + encodeEvaultToken(vaultToken),
          },
          url: `v1/vaults/${vaultId}/blobs/${blobId}`,
          method: 'GET',
          responseHandler: response =>
            response.headers.get('content-type') === 'text/html'
              ? response.text()
              : response.blob(),
        };
      },
      transformResponse: eVaultFileTransform,
    }),
  }),
});

export const { useGetVisitEvaultDocumentQuery } = oldEvaultApi;
